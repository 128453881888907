"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var DatalayerManagement = /** @class */ (function () {
    function DatalayerManagement(countryCode, language) {
        this.countryCode = countryCode;
        this.language = language;
        this.brandDesign = document.querySelector("meta[name='datalayer-design']").content || '';
        this.staging = document.querySelector("meta[name='datalayer-staging']").content || '';
        this.breakPoint = this.determineBreakPoint();
    }
    DatalayerManagement.prototype.getCountryCode = function () {
        return this.countryCode;
    };
    DatalayerManagement.prototype.getLanguage = function () {
        return this.language;
    };
    DatalayerManagement.prototype.getBrandDesign = function () {
        return this.brandDesign;
    };
    DatalayerManagement.prototype.getBreakpoint = function () {
        return this.breakPoint;
    };
    DatalayerManagement.prototype.getStage = function () {
        return this.staging;
    };
    DatalayerManagement.prototype.getOnLoadData = function () {
        return {
            lang: this.getLanguage(),
            country: this.getCountryCode(),
            brandDesign: this.getBrandDesign(),
            breakPoint: this.getBreakpoint(),
            stage: this.getStage(),
            pageName: '',
        };
    };
    DatalayerManagement.prototype.determineBreakPoint = function () {
        switch (true) {
            case window.matchMedia('(max-width: 480px)').matches:
                return "xs" /* ScreenSize.XS */;
            case window.matchMedia('(max-width: 720px)').matches:
                return "s" /* ScreenSize.S */;
            case window.matchMedia('(max-width: 960px)').matches:
                return "m" /* ScreenSize.M */;
            case window.matchMedia('(max-width: 1280px)').matches:
                return "l" /* ScreenSize.L */;
            case window.matchMedia('(max-width: 1600px)').matches:
                return "xl" /* ScreenSize.XL */;
            case window.matchMedia('(min-width: 1601px)').matches:
                return "xxl" /* ScreenSize.XXL */;
        }
    };
    return DatalayerManagement;
}());
exports.default = DatalayerManagement;
