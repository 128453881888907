"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FilterCategoryNames = exports.FilterInteractionElements = exports.FilterActionType = exports.TrackingActionType = void 0;
var TrackingActionType;
(function (TrackingActionType) {
    TrackingActionType["PAGE"] = "page";
    TrackingActionType["INTERACTION"] = "interaction";
})(TrackingActionType = exports.TrackingActionType || (exports.TrackingActionType = {}));
var FilterActionType;
(function (FilterActionType) {
    FilterActionType["INITIAL"] = "initial";
    FilterActionType["SELECTION"] = "selection";
    FilterActionType["DESELECTION"] = "deselection";
    FilterActionType["CHANGE"] = "change";
})(FilterActionType = exports.FilterActionType || (exports.FilterActionType = {}));
var FilterInteractionElements;
(function (FilterInteractionElements) {
    FilterInteractionElements["SLIDER"] = "Slider";
    FilterInteractionElements["DROPDOWN"] = "Dropdown";
    FilterInteractionElements["INPUT"] = "Input field";
    FilterInteractionElements["CHECKMARK"] = "Checkmark";
    FilterInteractionElements["OPTION_BUTTON"] = "Option button";
})(FilterInteractionElements = exports.FilterInteractionElements || (exports.FilterInteractionElements = {}));
var FilterCategoryNames;
(function (FilterCategoryNames) {
    FilterCategoryNames["MANUFACTURERS"] = "manufacturers";
    FilterCategoryNames["MODEL_NAMES"] = "modelNames";
    FilterCategoryNames["BODY_TYPES"] = "bodyTypes";
    FilterCategoryNames["FUEL_TYPES"] = "fuelTypes";
    FilterCategoryNames["BUDGET_TYPE"] = "budgetType";
    FilterCategoryNames["MONTHLY_BUDGET"] = "monthlyBudget";
    FilterCategoryNames["DEALER"] = "dealer";
    FilterCategoryNames["COLOR_EXTERIOR"] = "colorExteriors";
    FilterCategoryNames["NUMBER_OF_DOORS"] = "numberOfDoors";
    FilterCategoryNames["RENT_OPTIONS_PICK_UP_DATE"] = "rentOptionsPickUpDate";
    FilterCategoryNames["RENT_OPTIONS_RETURN_DATE"] = "rentOptionsReturnDate";
    FilterCategoryNames["RENT_OPTIONS_DEALER"] = "rentOptionsDealer";
    FilterCategoryNames["CATEGORIES"] = "categories";
    FilterCategoryNames["TYPE_CONSTRUCTS"] = "typeConstructs";
    FilterCategoryNames["CORPORATE_CUSTOMER_ID"] = "corporateCustomerId";
    FilterCategoryNames["CUSTOMER_TYPES_FILTER"] = "customerType";
    FilterCategoryNames["BALLOON_PAYMENT_AMOUNT"] = "balloonPaymentAmount";
    FilterCategoryNames["BALLOON_PAYMENT_PERCENTAGE"] = "balloonPaymentPercentage";
    FilterCategoryNames["CONDITIONS"] = "conditions";
    FilterCategoryNames["DOWN_PAYMENT_AMOUNT"] = "downPaymentAmount";
    FilterCategoryNames["DOWN_PAYMENT_PERCENTAGE"] = "downPaymentPercentage";
    FilterCategoryNames["DURATIONS"] = "durations";
    FilterCategoryNames["EQUIPMENTS"] = "equipments";
    FilterCategoryNames["INITIAL_REGISTRATION_DATE"] = "initialRegistrationDate";
    FilterCategoryNames["LT_EXTERIOR_COLORS"] = "ltExteriorColors";
    FilterCategoryNames["LT_FUEL_TYPES"] = "ltFuelTypes";
    FilterCategoryNames["NUMBER_OF_DOORS_SELECT"] = "numberOfDoorsSelect";
    FilterCategoryNames["ODOMETER_MILEAGE"] = "odometerMileage";
    FilterCategoryNames["ONLY_DISCOUNTED"] = "onlyDiscounted";
    FilterCategoryNames["POWER"] = "power";
    FilterCategoryNames["PRICE"] = "price";
    FilterCategoryNames["TRANSMISSION_TYPES"] = "transmissionTypes";
    FilterCategoryNames["YEARLY_MILEAGES"] = "yearlyMileages";
})(FilterCategoryNames = exports.FilterCategoryNames || (exports.FilterCategoryNames = {}));
