"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var PageAnalyticsController_1 = __importStar(require("./PageAnalyticsController"));
var tracking_1 = require("../types/tracking");
var ProductDetailsPageAnalyticsController = /** @class */ (function (_super) {
    __extends(ProductDetailsPageAnalyticsController, _super);
    function ProductDetailsPageAnalyticsController(datalayerManager) {
        return _super.call(this, 'ACS vehicle details', datalayerManager) || this;
    }
    ProductDetailsPageAnalyticsController.setProductData = function (vehicle, productId) {
        var _a, _b;
        // setting Vehicle Model Data
        var vehicleModel = window.tracking.product[0].vehicleModel[0];
        vehicleModel.manufacturer = vehicle.manufacturer;
        vehicleModel.name = vehicle.name;
        vehicleModel.year = (_a = vehicle.year) === null || _a === void 0 ? void 0 : _a.toString();
        vehicleModel.modelLine = vehicle.modelLine;
        vehicleModel.descriptionLong = vehicle.descriptionLong;
        vehicleModel.salesID = productId;
        vehicleModel.bodyType = vehicle.bodyType;
        vehicleModel.category = vehicle.category;
        vehicleModel.certifiedPreOwned = vehicle.certifiedPreOwned;
        vehicleModel.currentMileage = (_b = vehicle.currentMileage) === null || _b === void 0 ? void 0 : _b.toString();
        vehicleModel.currentMileageUnit = 'KILOMETERS';
        vehicleModel.initialRegistrationDate = vehicle.initialRegistrationDate;
        vehicleModel.currency = vehicle.currency;
        vehicleModel.endPrice_localCurrency = vehicle.endPriceLocalCurrency;
        vehicleModel.colorExterior = vehicle.colorExterior;
        vehicleModel.condition = vehicle.condition;
        vehicleModel.engine.fuelType = vehicle.engine.fuelType;
        vehicleModel.engine.emission = vehicle.engine.emission;
        vehicleModel.engine.transmission = vehicle.engine.transmission;
        vehicleModel.carClassificationCode = vehicle.category;
    };
    ProductDetailsPageAnalyticsController.setProductAddons = function (addons) {
        window.tracking.product[0].productAddons = addons;
    };
    // TODO define type of event value
    ProductDetailsPageAnalyticsController.setCheckoutEvent = function (eventValue) {
        if (!(eventValue instanceof Object) || (eventValue instanceof Object && !('productAddons' in eventValue))) {
            // wrong type
            return;
        }
        if (eventValue.productAddons) {
            ProductDetailsPageAnalyticsController.setProductAddons(eventValue.productAddons);
        }
        if (eventValue.filterValues) {
            var dealerData = eventValue.filterValues.find(function (filter) { return filter.filterName === 'rentOptionsDealer' && (filter.filterValue != null || filter.filterValue != undefined); });
            if (dealerData) {
                PageAnalyticsController_1.default.setDealerData(dealerData.filterValue);
            }
        }
        if (eventValue.vehicle) {
            ProductDetailsPageAnalyticsController.setProductData(eventValue.vehicle, eventValue.productId);
        }
        // send checkout event
        window.tracking.event[0].eventInfo.linkInformation = 'ACS to checkout';
        window.tracking.event[0].eventInfo.eventType = 'Vehicle Information';
        window.tracking.event[0].eventInfo.eventAction = 'Provided';
        PageAnalyticsController_1.default.sendAnalyticsEvent(PageAnalyticsController_1.AnalyticsEventType.INTERACTION);
    };
    ProductDetailsPageAnalyticsController.prototype.track = function (trackingAction) {
        if (trackingAction.action === tracking_1.TrackingActionType.PAGE &&
            'filterAction' in trackingAction.data &&
            trackingAction.data.filterAction === tracking_1.FilterActionType.INITIAL) {
            // set Vehicle Data
            if ('vehicle' in trackingAction.data && trackingAction.data.vehicle) {
                ProductDetailsPageAnalyticsController.setProductData(trackingAction.data.vehicle, trackingAction.data.productId);
            }
            // set Product Addons
            if ('productAddons' in trackingAction.data && trackingAction.data.productAddons) {
                ProductDetailsPageAnalyticsController.setProductAddons(trackingAction.data.productAddons);
            }
            // set Selected Filters Values
            if (trackingAction.data.filterValues) {
                PageAnalyticsController_1.default.setFilters(trackingAction.data.filterValues, trackingAction.action);
            }
        }
        // set Checkout event
        if ('eventType' in trackingAction.data && trackingAction.data.eventType === 'productCheckout') {
            ProductDetailsPageAnalyticsController.setCheckoutEvent(trackingAction.data.eventValue);
        }
    };
    return ProductDetailsPageAnalyticsController;
}(PageAnalyticsController_1.default));
exports.default = ProductDetailsPageAnalyticsController;
