"use strict";
// @ts-nocheck
Object.defineProperty(exports, "__esModule", { value: true });
exports.AnalyticsEventType = void 0;
var tracking_1 = require("../types/tracking");
var AnalyticsEventType;
(function (AnalyticsEventType) {
    AnalyticsEventType["INTERACTION"] = "interaction";
})(AnalyticsEventType = exports.AnalyticsEventType || (exports.AnalyticsEventType = {}));
var PageAnalyticsController = /** @class */ (function () {
    function PageAnalyticsController(pageName, datalayerManager) {
        var coreData = datalayerManager.getOnLoadData();
        coreData.pageName = pageName;
        this.setCoreAnalytics(coreData);
    }
    PageAnalyticsController.setFilters = function (filterValues, action) {
        // set Filter Values into Datalayer
        var eventType = action === 'page' ? 'pageView' : 'interaction';
        for (var _i = 0, filterValues_1 = filterValues; _i < filterValues_1.length; _i++) {
            var filter = filterValues_1[_i];
            if (!Array.isArray(filter.filterValue)) {
                filter.filterValue = [filter.filterValue];
            }
        }
        window.tracking.filter = filterValues;
        window.tracking.event[0].eventInfo.eventType = eventType;
        window.tracking.event[0].eventInfo.eventAction = 'Success';
        if (eventType === 'pageView') {
            PageAnalyticsController.setLinkInfo();
        }
        PageAnalyticsController.sendAnalyticsEvent(AnalyticsEventType.INTERACTION);
        // set dealer Data if filter "rentOptionsDealer" is existing
        var dealerData = filterValues.find(function (filter) { return filter.filterName === 'rentOptionsDealer' && (filter.filterValue != undefined || filter.filterValue != null); });
        if (dealerData) {
            PageAnalyticsController.setDealerData(dealerData.filterValue);
        }
    };
    PageAnalyticsController.sendEvent = function (info) {
        window.tracking.event[0].eventInfo.linkInformation = info;
        window.tracking.event[0].eventInfo.eventType = 'interAction';
        window.tracking.event[0].eventInfo.eventAction = 'Success';
        PageAnalyticsController.sendAnalyticsEvent(AnalyticsEventType.INTERACTION);
    };
    PageAnalyticsController.setDealerData = function (dealerData) {
        if (dealerData instanceof Object && 'companyId' in dealerData) {
            window.tracking.dealerData = dealerData;
        }
    };
    PageAnalyticsController.setFilterEvent = function (selectedFilter) {
        var index = window.tracking.filter.findIndex(function (filter) { return filter.filterName === selectedFilter.filterName; });
        if (!Array.isArray(selectedFilter.filterValue)) {
            selectedFilter.filterValue = [selectedFilter.filterValue];
        }
        if (index === -1) {
            window.tracking.filter.push(selectedFilter);
        }
        else {
            window.tracking.filter[index] = selectedFilter;
        }
        // set Event information
        PageAnalyticsController.sendEvent('ACS selected filter ' + selectedFilter.filterName);
    };
    PageAnalyticsController.setMappingForFilterNames = function (selectedFilter) {
        switch (selectedFilter.filterName) {
            case tracking_1.FilterCategoryNames.YEARLY_MILEAGES.valueOf(): {
                selectedFilter.filterName = 'km/y';
                break;
            }
            case tracking_1.FilterCategoryNames.MANUFACTURERS.valueOf(): {
                selectedFilter.filterName = 'MF';
                break;
            }
            case tracking_1.FilterCategoryNames.MODEL_NAMES.valueOf(): {
                selectedFilter.filterName = 'Model';
                break;
            }
            case tracking_1.FilterCategoryNames.FUEL_TYPES.valueOf():
            case tracking_1.FilterCategoryNames.LT_FUEL_TYPES.valueOf(): {
                selectedFilter.filterName = 'Fuel';
                break;
            }
            case tracking_1.FilterCategoryNames.TRANSMISSION_TYPES.valueOf(): {
                selectedFilter.filterName = 'Gear';
                break;
            }
            case tracking_1.FilterCategoryNames.COLOR_EXTERIOR.valueOf():
            case tracking_1.FilterCategoryNames.LT_EXTERIOR_COLORS.valueOf(): {
                selectedFilter.filterName = 'ExCol';
                break;
            }
            case tracking_1.FilterCategoryNames.EQUIPMENTS.valueOf(): {
                selectedFilter.filterName = 'Equip';
                break;
            }
            case tracking_1.FilterCategoryNames.INITIAL_REGISTRATION_DATE.valueOf(): {
                selectedFilter.filterName = 'IniReg';
                break;
            }
            case tracking_1.FilterCategoryNames.ODOMETER_MILEAGE.valueOf(): {
                selectedFilter.filterName = 'CurrMile';
                break;
            }
            case tracking_1.FilterCategoryNames.BODY_TYPES.valueOf(): {
                selectedFilter.filterName = 'Body';
                break;
            }
            default: {
                // Do nothing
                break;
            }
        }
    };
    PageAnalyticsController.setLinkInfo = function () {
        var info;
        switch (window.tracking.core.pageInfo.pageName) {
            case 'ACS quick search':
                info = 'Initial';
                break;
            case 'ACS result list':
                info = 'Result list';
                break;
            case 'ACS vehicle details':
                info = 'Vehicle details';
                break;
            default:
                info = '';
                break;
        }
        window.tracking.event[0].eventInfo.linkInformation = info;
    };
    PageAnalyticsController.sendAnalyticsEvent = function (type) {
        var _a, _b, _c, _d;
        /* eslint-disable @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call */
        if (typeof _satellite !== 'undefined' && _satellite) {
            (_satellite === null || _satellite === void 0 ? void 0 : _satellite.track) ? (_a = _satellite === null || _satellite === void 0 ? void 0 : _satellite.track) === null || _a === void 0 ? void 0 : _a.call(_satellite, type) : console.debug('Analytics: _satellite.track is not loaded!');
            (_satellite === null || _satellite === void 0 ? void 0 : _satellite.logger)
                ? (_d = (_c = (_b = this._satellite) === null || _b === void 0 ? void 0 : _b.logger) === null || _c === void 0 ? void 0 : _c.info) === null || _d === void 0 ? void 0 : _d.call(_c, "Analytics: Event of type '".concat(type, "' was fired!"))
                : console.debug('Analytics: _satellite.logger is not loaded!');
        }
        else {
            console.debug('Analytics: _satellite library was not loaded!');
        }
        /* eslint-enable */
    };
    PageAnalyticsController.prototype.setCoreAnalytics = function (coreData) {
        window.tracking.core.pageInfo.pageName = coreData.pageName;
        PageAnalyticsController.setLinkInfo();
        window.tracking.core.pageInfo.language = coreData.lang;
        window.tracking.core.pageInfo.market = coreData.country;
        window.tracking.core.category.primaryCategory =
            document.querySelector("meta[name='datalayer-sitePrimaryCategory']").content || '';
        window.tracking.core.category.secondaryCategory =
            document.querySelector("meta[name='datalayer-siteSecondaryCategories']").content || '';
        window.tracking.core.attributes.viewChange = coreData.pageName;
        window.tracking.core.attributes.brand = coreData.brandDesign;
        window.tracking.core.stagingEnvironment = coreData.stage;
        window.tracking.design.browserResolutionBreakpoint = coreData.breakPoint;
        window.tracking.event[0].eventInfo.eventType = 'pageView';
        window.tracking.event[0].eventInfo.eventAction = 'Success';
    };
    PageAnalyticsController.setSelectedFilters = function (trackingAction) {
        if ('selectedFilter' in trackingAction.data) {
            if (trackingAction.data.selectedFilter) {
                PageAnalyticsController.setMappingForFilterNames(trackingAction.data.selectedFilter);
                PageAnalyticsController.setFilterEvent(trackingAction.data.selectedFilter);
                if (trackingAction.data.selectedFilter.filterName === 'rentOptionsDealer' && trackingAction.data.dealerData) {
                    PageAnalyticsController.setDealerData(trackingAction.data.dealerData);
                }
            }
        }
        else if ('filterValues' in trackingAction.data) {
            if (trackingAction.data.filterValues &&
                (trackingAction.action === tracking_1.TrackingActionType.PAGE || trackingAction.action === tracking_1.TrackingActionType.INTERACTION)) {
                trackingAction.data.filterValues.forEach(function (selectedFilter) {
                    PageAnalyticsController.setMappingForFilterNames(selectedFilter);
                });
                PageAnalyticsController.setFilters(trackingAction.data.filterValues, trackingAction.action);
            }
        }
    };
    return PageAnalyticsController;
}());
exports.default = PageAnalyticsController;
